.recruit {
    position: relative;
    width: 100%;
    top: 30rem;
    height: 1200px;
    background: url('./recruit-bg.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    z-index: 1000;
}

.recruit.heading {
    display: block;
    text-align: center;
}

.recruit-content {
    max-width: 800px;
    margin-top: 5rem;
    padding-top: 3rem;
    border-top: 1px solid #586166;
}

.recruit-card {
    background-color: rgba(88, 97, 102, 0.7);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}


.right {

    color: #ffffff;
    padding: 1.5rem 0 0 0;
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.right .job {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}

.job-desc {
    margin-top: 1.5rem;
}

.desc-item {
    padding-bottom: 5px;
    margin-bottom: 1rem;
    border-bottom: 1px dashed #ffff;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.desc-key {
    font-size: 1.5rem;
    margin-right: 40px;
}

.desc-value {
    letter-spacing: 1px;
}

.recruit-btn {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
    height: 70px;
    width: 38%;
    position: relative !important;
    background-color: #ffff !important;
    font-size: 1.5rem;
    letter-spacing: 3px;
}

@media (max-width: 800px) {


    .recruit-content {
        width: 80% !important;
        margin: auto;
    }
}

@media (max-width: 650px) {
    .recruit-btn {
        width: 90%;
        margin: auto;
    }

    .right {
        flex-direction: column;
    }

    .job-desc {
        width: 90%;
        margin: auto;
    }

    .desc-item {
        padding-left: 2rem;
    }

}